<template>
    <div class="container">
        <qy-top-nav title="经销商消息认证支付"/>
        <div class="qrcode" v-html="innerHtml"></div>
        <div>支付金额：{{payMoney}}元</div>
        <div v-text="qrMsg"></div>
    </div>
</template>
<script>
    import request from "@/utils/request";
    import { Toast } from 'vant';
    import wx from "weixin-js-sdk";
    export default {
        name: "DeliverInfoPay",
        data(){
            return{
                payMoney:0.00,
                innerHtml:"",
                qrMsg:"",
                dealerId:this.getUrlParam("dealerId"),
            }
        },
        created(){
          this.getList();
        },
        methods:{
            getList(){
                //生产支付二维码
                let that = this;
                let redirectUrl=location.origin+"/dealerInfo";
                request.post("/api/dealer/payment",{
                    dealerId:this.dealerId,
                    // redirectUrl:"https://"+location.hostname+"/dealerInfo"
                    redirectUrl:redirectUrl
                }).then(result => {
                    if (result.success){
                        let data = result.data;
                        if(data.code == 8){  //支付成功
                            //通联直接跳转
                            let payUrl=decodeURIComponent(data.data);
                            this.openWxH5Pay(payUrl);
                           //
                           /* that.creatQrCode(data.data);
                            this.openAliPay(data.data)*/
                        }else {
                            return Toast.fail(data.msg);
                        }
                    }else {
                        return Toast.fail(result.msg || "生成支付码失败");
                    }
                });
            },
            creatQrCode(datas) {
                let data = decodeURIComponent(datas.data);
                let payImg = "http://121.41.18.160:8078/111.aspx?con="+data;
                this.qrMsg="长按二维码识别进行支付";
                this.payMoney = datas.payMoney;
                this.innerHtml = "<img src='"+payImg+"'/>";
            },
        }
    }
</script>
<style scoped>
    div.container{
        text-align: center;
        padding: 0 0;
        margin: 0 0;
        font-size: 14px;
    }
    .qrcode{
        display: inline-block;
        margin-top:20%;
    }
</style>